<template>
    <div class="storeInfo">
        <van-field v-model="form.mobile" type="tel" label="核销人手机号" placeholder="请输入核销人手机号" />

        <div class="footer">
            <div>
                <van-button block :loading="btnLoading" type="info" loading-text="加载中..." @click="sureBtnClick()">添加
                </van-button>
            </div>
        </div>

    </div>
</template>



<script>
import { Toast } from 'vant';
import { Notify } from "vant";
export default {


    data() {

        return {
            btnLoading: false,
            form: {
                mobile: null,
                adcId:null

            }


        };
    },
    created() {
        this._Id = this.$route.query.Id
        this.form.adcId = this.$route.query.storeAdcId
        var merchant = localStorage.getItem('xxMerchantInfo')
        this.merchantId = JSON.parse(merchant).merchantId
    },
    mounted() {


    },

    methods: {
        sureBtnClick() {
            if (!this.form.mobile) {
                Toast('请输入手机号')
                return
            }
            var params = { ...this.form }
            this.$http.addAdcUser(params).then(res => {
                if (res.code == 200) {
                    this.$router.go(-1)
                    return
                }
                Notify({ type: "primary", message: res.msg });
            })

        }




    },
};
</script>
<style lang="scss">
.coverPic {
    .van-uploader__preview-image {
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;
    }


}

.coverVideo {
    .van-uploader__file {
        width: 200px;
        height: 150px;

    }


}
</style>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



.storeInfo {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: white;
    padding: 20px 0px;
    box-sizing: border-box;

    .item1 {
        height: 40px;
        font-size: 14px;
    }

    .cover1 {
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;

    }

    .videoClass {
        width: 200px;
        height: 150px;
        object-fit: cover;
    }

    .footer {
        padding: 50px 15px 20px 15px;
        box-sizing: border-box;
    }

}
</style>